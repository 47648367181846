import React, { useEffect, useState } from 'react';
import { Page } from '../../../../components/Page';
import * as S from '../../index.styles';
import * as utils from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ExerciseCard from '../../components/ExerciseCard';
import { isToday, isWithinInterval, subDays, format, parseISO } from 'date-fns';
import { useNavigate, useParams } from 'react-router';
import { ExerciseTypes } from '@apps/common-utilities';
import { useSelector } from 'react-redux';
import { Spinner, useModal } from '@apps/common-ui';
import Mixpanel from '../../../../services/Mixpanel';
import { RootState } from '../../../../state/store';
import { IExerciseDaySummary } from '@apps/common-utilities/src/types/exerciseTypes';
import BreatherCard from '../components/BreatherCard';
import { TenantApi } from '../../../../api/UsersApi';
import { RequestMethod, useApiRequest } from '../../../../hooks/useApiRequest';
import toast from 'react-hot-toast';
import BaselineWarning from '../../components/BaselineModals/BaselineWarning';
import ExerciseCompletionDateModal from '../../components/ExerciseCompletionDateModal';
import PostExerciseBaseline from '../../components/BaselineModals/PostExerciseBaseline';
import { PostExerciseMetrics } from '../../../../types/models';
import ExerciseCompletionCheckModal from '../../components/ExerciseCompletionCheckModal';

const RMTForDay = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedExercise, setSelectedExercise] = useState<ExerciseTypes.IExerciseSummary>();
    const [daySummary, setDaySummary] = useState<ExerciseTypes.IExerciseDaySummary>();
    const [rmtExercises, setRMTExercises] = useState<ExerciseTypes.IExerciseSummary[]>([]);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const { tenantUser } = useSelector((state: RootState) => state.session);

    const isCurrentExercise = (index: number) => index === currentIndex;
    const allAreCompleted = rmtExercises.every(exercise => exercise.complete);

    const { date } = useParams<{date: string}>();
    const [isCompletable, setIsCompletable] = useState(false);

    const { isOpen: isCompletionCheckOpen, openModal: openCompletionCheckModal, closeModal: closeCompletionCheckModal } = useModal();
    const { isOpen: isCompletionDateOpen, openModal: openCompletionDateModal, closeModal: closeCompletionDateModal } = useModal();
    const { isOpen: isPostBaselineOpen, openModal: openPostBaselineModal, closeModal: closePostBaselineModal } = useModal();
    const { isOpen: isWarningOpen, openModal: openWarningModal, closeModal: closeWarningModal } = useModal();

    const startTime = new Date();
    const today = format(new Date(), 'yyyy-MM-dd');
    const assignedDate = date ? format(parseISO(date), 'yyyy-MM-dd') : today;
    const [warningType, setWarningType] = useState(ExerciseTypes.WarningType.MULTIPLE);
    const [completionDate, setCompletionDate] = useState(assignedDate);
    const [submittingBaseline, setSubmittingBaseline] = useState(false);
    const [isInputData, setIsInputData] = useState(false);
    const [completionDateBaseline, setCompletionDateBaseline] = useState<ExerciseTypes.IFetchedBaseline>();
    const [submittedPostBaseline, setSubmittedPostBaseline] = useState(false);
    const [shouldRedirectToExercisePage, setShouldRedirectToExercisePage] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const { callApi: fetchCompletionDateBaselineApi } = useApiRequest<ExerciseTypes.IFetchedBaseline>(RequestMethod.GET);
    const { callApi: createSubmission } = useApiRequest(RequestMethod.POST);

    const fetchCompletionDateBaseline = async () => {
        if (!tenantUser) {
            return;
        }
        const { response, error } = await fetchCompletionDateBaselineApi(`/users/${tenantUser.id}/exercise-plans/exercise-baselines?calendarCompletionDate=${completionDate}`);
        if (error.hasError) {
            console.error(error.message);
            return;
        }
        if (response.data) {
            setCompletionDateBaseline(response.data);
        }
    };

    const loadHistory = async () => {
        if (date && tenantUser) {
            setLoadingHistory(true);
            TenantApi.get(`/users/${tenantUser?.id}/exercise-plans/daily-summary`, {
                params: {
                    startDate: new Date(date).toISOString(),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    type: utils.ExerciseFilter.RMT
                } })
                .then((res: IExerciseDaySummary) => {
                    setDaySummary(res);
                    const rmt = res.exercises;
                    setRMTExercises(rmt);
                    const nextExerciseIndex = rmt.findIndex((exercise) => !exercise.complete && exercise.exerciseInfo.type === ExerciseTypes.ExerciseType.SETS_AND_BREATHS);
                    if (nextExerciseIndex) {
                        setCurrentIndex(nextExerciseIndex);
                    }
                }).catch(() => {
                    console.error('Error fetching exercise summary');
                }).finally(() => {
                    setLoadingHistory(false);
                });
        }
    }

    useEffect(() => {
        loadHistory();
    }, [date, tenantUser]);

    useEffect(() => {
        loadHistory();
        if (submittedPostBaseline) {
            setSubmittedPostBaseline(false);
        }
    }, [submittedPostBaseline]);

    useEffect(() => {
        if (date) {
            setIsCompletable(daySummary?.complete || isWithinInterval(new Date(date), { start: subDays(new Date(), 6), end: new Date() }));
        }
    }, [date, daySummary]);

    useEffect(() => {
        if (shouldRedirectToExercisePage && selectedExercise) {
            navigate(`/modal/exercise/${date}/${selectedExercise.id}`);
        }
    }, [shouldRedirectToExercisePage, selectedExercise]);

    if (loadingHistory) {
        return <Spinner />;
    }

    if (!date || !daySummary) {
        return null;
    }

    const submitPostExercise = async (metrics: PostExerciseMetrics, hasWarning?: boolean) => {
        setSubmittingBaseline(true);
        const submission: ExerciseTypes.IAssignedExerciseSubmission = {
            ...metrics,
            id: null,
            startTime,
            assignedExerciseId: selectedExercise?.id ? selectedExercise.id : -1,
            exerciseBaselineId: completionDateBaseline?.id ? completionDateBaseline.id : -1,
            calendarCreatedAt: format(new Date(), 'yyyy-MM-dd'),
            calendarCompletionDate: completionDate,
        };

        const res = await createSubmission('/exercise-plans/exercise-submissions', submission);
        setSubmittingBaseline(false);
        if (res.error.hasError) {
            toast.error('Error submitting exercise, please try again and contact support if the problem persists.');
            return;
        }
        closePostBaselineModal();
        toast.success('Post-exercise submitted successfully');
        setSubmittedPostBaseline(true);
        if (!hasWarning && !isInputData) {
            navigate(-1);
        }
    };

    const exerciseStart = (exercise: ExerciseTypes.IExerciseSummary, index: number) => {
        Mixpanel.track('web_rmt_start_exercise', {
            exercise: exercise.exerciseInfo.title,
            date,
            exerciseNumber: index + 1,
        });
        const assignedExercise = rmtExercises.find((ae) => ae.id === exercise.id);
        if (assignedExercise) {
            setSelectedExercise(assignedExercise);
            setShouldRedirectToExercisePage(true);
        }
    };

    const onExerciseStart = (exercise: ExerciseTypes.IExerciseSummary, index: number) => {
        const assignedExercise = rmtExercises.find((ae) => ae.id === exercise.id);

        if (assignedExercise) {
            setSelectedExercise(assignedExercise);
            if (isToday(parseISO(date)) || daySummary.complete) {
                exerciseStart(assignedExercise, index);
            } else {
                openCompletionCheckModal();
                setSelectedIndex(index);
            }
        }
    };

    const shouldShowPostExerciseWarningPost = (metrics: PostExerciseMetrics) => {
        let warningCount = 0;
        if (metrics.heartRate && metrics.heartRate < 50) {
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_LOW);
            warningCount += 1;
        } else if (metrics.heartRate && metrics.heartRate > 150) {
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_HIGH);
            warningCount += 1;
        }

        if ((metrics.immediateSpO2Percent && (metrics.immediateSpO2Percent < 85 || metrics.immediateSpO2Percent < daySummary.spO2PercentTarget)) || (metrics.lowestSpO2Percent && (metrics.lowestSpO2Percent < 85 || metrics.lowestSpO2Percent < daySummary.spO2PercentTarget))) {
            setWarningType(ExerciseTypes.WarningType.SPO2);
            warningCount += 1;
        }

        if (warningCount > 0 && !isInputData) {
            if (warningCount > 1) {
                setWarningType(ExerciseTypes.WarningType.MULTIPLE);
            }
            submitPostExercise(metrics, true);
            openWarningModal();
        } else {
            submitPostExercise(metrics);
            setIsInputData(false);
        }
    };

    const updateCompletionDate = (dateCompleted: string) => {
        setCompletionDate(dateCompleted);
        closeCompletionDateModal();
        setIsInputData(true);
        openPostBaselineModal();
    };

    const getNumExercisesString = (): string => {
        const numNonRMT = rmtExercises.length;
        return `${numNonRMT} ${numNonRMT > 1 ? 'exercises' : 'exercise'}`;
    };

    return (
        <>
            {isCompletionCheckOpen && (
                <ExerciseCompletionCheckModal
                  onClose={closeCompletionCheckModal}
                  onSubmit={(startExerciseToday: boolean) => {
                    if (startExerciseToday) {
                        exerciseStart(selectedExercise as ExerciseTypes.IExerciseSummary, selectedIndex);
                    } else {
                        openCompletionDateModal();
                    }
                    closeCompletionCheckModal();
                  }}
                />
            )}
            {isCompletionDateOpen && (
                <ExerciseCompletionDateModal
                  onClose={closeCompletionDateModal}
                  onSubmit={updateCompletionDate}
                  assignedDate={date}
                  submitting={submittingBaseline}
                />
            )}
            {isPostBaselineOpen && selectedExercise && (
                <PostExerciseBaseline
                  exercise={selectedExercise}
                  onClose={() => {
                    closePostBaselineModal();
                    setIsInputData(false);
                }}
                  checkSubmission={(metrics: PostExerciseMetrics) => shouldShowPostExerciseWarningPost(metrics)}
                  showOxygen={completionDateBaseline ? !!completionDateBaseline.flowRate : true}
                  submitting={submittingBaseline}
                />
            )}
            {isWarningOpen && (
            <BaselineWarning
              onClose={() => closeWarningModal()}
              sp02Target={daySummary?.spO2PercentTarget as number}
              openBaselineModal={() => {
                closeWarningModal();
            }}
              warningType={warningType}
            />
            )}
            <Page>
                <S.Section>
                    <S.BackButton
                      buttonType="tertiary"
                      to="/breather"
                    >
                        <FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back to all upcoming breather sessions
                    </S.BackButton>
                </S.Section>
                <S.Section>
                    {isToday(new Date(date)) ? (<S.Heading>Today&apos;s Breather Sessions</S.Heading>) : (<S.Heading>{utils.getMonthAndDate(new Date(date)) } Exercises</S.Heading>)}
                </S.Section>
                <S.Section>
                    {isCompletable
                            ? <S.Subheading>{utils.getMonthAndDate(new Date(date))}, {new Date(date).getFullYear()} has {getNumExercisesString()} </S.Subheading> : <S.Subheading>These exercises will unlock on {utils.getMonthAndDate(new Date(date))}</S.Subheading>}
                </S.Section>
                <S.Section>
                    {allAreCompleted && isCompletable && <S.AllCompletedBanner>You&apos;re done for today!</S.AllCompletedBanner>}
                    {!!rmtExercises.length && (
                        <S.CardContainer>
                                {rmtExercises.map((exercise: ExerciseTypes.IExerciseSummary, index: number) => (
                                    <ExerciseCard
                                      key={exercise.id}
                                      exercise={exercise}
                                      onStart={() => onExerciseStart(exercise, index)}
                                      isCurrentExercise={isCurrentExercise(index)}
                                      canComplete={isCompletable}
                                      exerciseIndex={`${index + 1} of ${rmtExercises.length}`}
                                      date={date}
                                      isPlanCompleted={daySummary.complete}
                                    />
                            ))}
                        </S.CardContainer>
                        )}
                    <BreatherCard />
                </S.Section>
            </Page>
        </>
    );
};

export default RMTForDay;
