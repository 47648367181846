import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { theme } from '../theme';

type ButtonTypeKey = 'secondary' | 'tertiary';

type ButtonStyleKey = 'danger' | 'warning' | 'success';

type ButtonType = {
  [K in ButtonTypeKey]: {
    background: string;
    borderStyle: string;
    borderWidth: string;
    [key: string]: string
  }
}

type ButtonStyle = {
  [K in ButtonStyleKey]: {
    color: string;
    borderColor: string;
    background: string;
    [key: string]: string
  }
}

const buttonStyles: ButtonStyle = {
    danger: {
        color: theme.text.error,
        borderColor: theme.text.error,
        borderStyle: 'solid',
        background: theme.text.error,
    },
    warning: {
        color: theme.text.warning,
        borderColor: theme.text.warning,
        borderStyle: 'solid',
        background: theme.text.warning,
    },
    success: {
        color: theme.text.success,
        borderColor: theme.text.success,
        borderStyle: 'solid',
        background: theme.text.success,
    },
};

const buttonTypes: ButtonType = {
    // no background, just border
    secondary: {
        background: 'transparent',
        borderWidth: '2px',
        borderStyle: 'solid',
    },
    // no background, no border
    tertiary: {
        background: 'transparent',
        borderWidth: '2px',
        borderStyle: 'none',
        borderColor: 'transparent'
    },
};

type ButtonSizeKey = 'small' | 'medium' | 'large';

type ButtonSizes = {
  [K in ButtonSizeKey]: {
    fontSize: string;
    padding: string;
  }
};

const buttonSizes: ButtonSizes = {
    small: {
        fontSize: '0.8rem',
        padding: '0.5rem 1rem',
    },
    medium: {
        fontSize: '1rem',
        padding: '0.5rem 1rem',
    },
    large: {
        fontSize: '1.5rem',
        padding: '0.5rem 1rem',
    }
};

type ButtonProps = {
    buttonStyle?: ButtonStyleKey
    buttonType?: ButtonTypeKey
    size?: ButtonSizeKey
}

const defaultStyles: {[key: string]: string} = {
    color: theme.buttons.blue,
    borderColor: theme.buttons.blue,
    background: theme.buttons.blue,
    borderWidth: '2px',
    borderStyle: 'solid',
};

const getProperty = (prop: any, props: ButtonProps) => {
    let style = defaultStyles[prop];
    if (props.buttonStyle) {
        style = buttonStyles[props.buttonStyle][prop];
    }
    if (props.buttonType && buttonTypes[props.buttonType][prop]) {
        style = buttonTypes[props.buttonType][prop];
    }
    if (prop === 'color' && !props.buttonType) {
        style = theme.text.white;
    }
    return style;
};

export const ButtonStyles = css<ButtonProps>`
  color: ${props => getProperty('color', props)};
  background: ${props => getProperty('background', props)};
  font-size: ${props => (props.size ? buttonSizes[props.size].fontSize : buttonSizes.medium.fontSize)};
  padding: ${props => (props.size ? buttonSizes[props.size].padding : buttonSizes.medium.padding)};
  border-width: ${props => getProperty('borderWidth', props)};
  border-color: ${props => getProperty('borderColor', props)};
  border-style: ${props => getProperty('borderStyle', props)};
  border-radius: 6px;
  margin: 5px;
  cursor: pointer;
  position: relative;
  font-weight: 600;

  &:hover {
    opacity: 0.8;
  }

  svg:first-of-type {
    margin-right: 5px;
  }

  svg:last-of-type {
    margin-left: 5px;
  }
`;

// See here for the reason for this extra config:
// https://gist.github.com/meddokss/009cdb5192e487654c0d90cd8f692214
export const Button = styled.button.withConfig<ButtonProps>({
    shouldForwardProp: (prop) => !['buttonStyle', 'buttonType', 'size'].includes(prop),
})`
  ${ButtonStyles}
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const LinkButton = styled(Link)<ButtonProps>`
  ${ButtonStyles}
  text-decoration: none;
  font-weight: bold;
`;

export const FullWidthButtonStyles = css`
  display: flex;
  width: 374px;
  height: 55px;
  align-self: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 2.2;
  @media (max-width: 780px) {
    width: 250px;
  }
`;

export const FullWidthButton = styled.button`
  ${ButtonStyles}
  ${FullWidthButtonStyles}
`;

export const FullWidthLinkButton = styled(Link)<ButtonProps>`
  ${ButtonStyles}
  ${FullWidthButtonStyles}
`;
