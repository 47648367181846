import { Widget } from '@typeform/embed-react';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import NotFound from '../../components/common/NotFound';
import JotformEmbed from '../../components/JotformEmbed';
import { useActionLoader } from '../../hooks/useActionLoader';
import { completeForm } from '../../state/reducers/coaching/actions';
import { RootState } from '../../state/store';
import * as S from './index.styles';

const Form = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, forms } = useSelector((state: RootState) => ({
        user: state.session.user,
        forms: state.coaching.forms
    }));
    const { formId } = useParams<{ formId: string }>();
    const { callAction: finishForm } = useActionLoader(completeForm);
    const { sourcePage } = location.state || {};

    const form = forms.find(f => f.jotformId === formId);

    if (!formId || !user) {
        return null;
    }

    const onCompleteForm = () => {
        finishForm({ formId });
        if (sourcePage === 'onboarding') {
            navigate('/onboarding');
        } else {
            navigate('/');
        }
    };

    return (
        <S.FormContainer>
            {form && !!form.embedUrl
                ? (
                    <JotformEmbed
                      src={form.embedUrl}
                      onCompleteForm={onCompleteForm}
                    />
            )
            : <NotFound />}
        </S.FormContainer>
    );
};

export default Form;
