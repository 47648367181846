import { Modal } from '@apps/common-ui';
import React from 'react';
import * as S from './index.styles';

interface Props {
    showModal: boolean;
    dismissModal: () => void;
}

const BreatherCleaningTips = ({ showModal, dismissModal }: Props) => (
    <Modal showModal={showModal} dismissModal={dismissModal} title="Breather Cleaning Tips" minWidth="60%">
        <S.ModalContent>
            <S.Block>
                <S.Paragraph>Proper cleaning is essential for maintaining the performance and hygiene of your respiratory muscle training device.</S.Paragraph>
                <S.Paragraph>Here’s a step-by-step guide on how to clean the Breather:</S.Paragraph>
            </S.Block>
            <S.Block>
                <S.List>
                    <li><b>Use warm, soapy water:</b> Clean your Breather regularly with mild, warm soapy water to prevent saliva build-up during training.</li>
                    <li><b>Separate the device:</b> Detach the body section of the device from the mouthpiece.</li>
                    <li><b>Swirl in soapy water:</b> Swirl both sections in the soapy water solution.</li>
                    <li><b>Rinse thoroughly:</b> Rinse the Breather well with clean water.</li>
                    <li><b>Remove excess water:</b> Shake off excess water and tap the device 3-5 times against your palm to dispel extra moisture.</li>
                    <li><b>Set dials and clear moisture:</b> Set both dials to 1 and inhale and exhale sharply to release any remaining moisture.</li>
                    <li><b>Dry the device:</b> Towel dry the outer surfaces, then air dry the Breather on a clean paper towel.</li>
                    <li><b>Store properly:</b> Once completely dry, store the Breather in a non-humid location. You can also store it in a plastic bag if desired.</li>
                </S.List>
                <S.Paragraph>For a deeper cleaning, which is recommended if your device has been exposed to a large amount of saliva or other debris, or if you have a respiratory infection, you can follow these additional steps:</S.Paragraph>
                <S.List>
                    <li><b>Soak in vinegar solution:</b> Place the Breather in a solution of 1 part vinegar to 3 parts warm water.</li>
                    <li><b>Soak time:</b> Let it soak for 5 minutes (no more than 15 minutes).</li>
                    <li><b>Rinse and dry:</b> Rinse thoroughly and air dry as recommended above.</li>
                </S.List>
                <S.Paragraph>Remember, proper cleaning helps maintain the effectiveness of your Breather and ensures hygienic use.</S.Paragraph>
            </S.Block>
        </S.ModalContent>
    </Modal>

);

export default BreatherCleaningTips;
