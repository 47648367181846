import { Spinner, useModal } from '@apps/common-ui';
import { ExerciseTypes } from '@apps/common-utilities';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { daysInWeek, isSameDay, isToday, isWithinInterval, setDay, subDays, format, set, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useActionLoader } from '../../../../hooks/useActionLoader';
import Mixpanel from '../../../../services/Mixpanel';
import { createExerciseBaseline, isBaselineRequired } from '../../../../state/reducers/coaching/actions';
import { RootState } from '../../../../state/store';
import BaselineWarning from '../../components/BaselineModals/BaselineWarning';
import PreExerciseBaseline from '../../components/BaselineModals/PreExerciseBaseline';
import PreExerciseChecklist from '../../components/BaselineModals/PreExerciseChecklist';
import ExerciseCard from '../../components/ExerciseCard';
import * as S from '../../index.styles';
import * as utils from '../../utils';
import { IExerciseDaySummary } from '@apps/common-utilities/src/types/exerciseTypes';
import { Routes } from '../../../../api/Routes';
import { Page } from '../../../../components/Page';
import { TenantApi } from '../../../../api/UsersApi';
import ExerciseCompletionDateModal from '../../components/ExerciseCompletionDateModal';
import ExerciseCompletionCheckModal from '../../components/ExerciseCompletionCheckModal';
import PostExerciseBaseline from '../../components/BaselineModals/PostExerciseBaseline';
import toast from 'react-hot-toast';
import { RequestMethod, useApiRequest } from '../../../../hooks/useApiRequest';
import { PostExerciseMetrics } from '../../../../types/models';

const ExercisesForDay = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    const [warningType, setWarningType] = useState(ExerciseTypes.WarningType.MULTIPLE);

    const { callAction: createCompletionDateBaseline, done: completionDateBaselineSaved, error: completionDateBaselineError } = useActionLoader(createExerciseBaseline, {
        successMessage: 'Pre-exercise submitted successfully',
        errorMessage: 'Error submitting metrics please try again or contact support.'
    });

    const { callAction: createBaseline, done: doneSavingBaseline, error: baselineError } = useActionLoader(createExerciseBaseline, {
        errorMessage: 'Error submitting metrics please try again or contact support.'
    });
    const [selectedExercise, setSelectedExercise] = useState<ExerciseTypes.IExerciseSummary>();
    const [daySummary, setDaySummary] = useState<ExerciseTypes.IExerciseDaySummary>();
    const [physicalExercises, setPhysicalExercises] = useState<ExerciseTypes.IExerciseSummary[]>([]);
    const [loadingHistory, setLoadingHistory] = useState(false);
    const [warningTriggered, setWarningTriggered] = useState(false);
    const [shouldRedirectToExercisePage, setShouldRedirectToExercisePage] = useState(false);
    const { tenantUser } = useSelector((state: RootState) => state.session);

    const allAreCompleted = physicalExercises.every(exercise => exercise.complete);
    const isCurrentExercise = (index: number) => index === currentIndex;

    const { date } = useParams<{date: string}>();
    const { id } = useParams<{id: string}>();
    const [isCompletable, setIsCompletable] = useState(false);

    const { isOpen: isChecklistOpen, openModal: openChecklistModal, closeModal: closeChecklistModal } = useModal();
    const { isOpen: isPreBaselineOpen, openModal: openPreBaselineModal, closeModal: closePreBaselineModal } = useModal();
    const { isOpen: isWarningOpen, openModal: openWarningModal, closeModal: closeWarningModal } = useModal();
    const { isOpen: isCompletionCheckOpen, openModal: openCompletionCheckModal, closeModal: closeCompletionCheckModal } = useModal();
    const { isOpen: isCompletionDateOpen, openModal: openCompletionDateModal, closeModal: closeCompletionDateModal } = useModal();
    const { isOpen: isPostBaselineOpen, openModal: openPostBaselineModal, closeModal: closePostBaselineModal } = useModal();

    const today = format(new Date(), 'yyyy-MM-dd');
    const assignedDate = date ? format(parseISO(date), 'yyyy-MM-dd') : today;
    const [completionDate, setCompletionDate] = useState(assignedDate);
    const [isInputData, setIsInputData] = useState(false);
    const [completionDateBaseline, setCompletionDateBaseline] = useState<ExerciseTypes.IFetchedBaseline>();
    const [submittingBaseline, setSubmittingBaseline] = useState(false);
    const [submittedPostBaseline, setSubmittedPostBaseline] = useState(false);
    const { callApi: createSubmission } = useApiRequest(RequestMethod.POST);
    const startTime = new Date();
    const { callApi: fetchCompletionDateBaselineApi } = useApiRequest<ExerciseTypes.IFetchedBaseline>(RequestMethod.GET);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [fetchingBaseline, setFetchingBaseline] = useState(false);

    const fetchBaselineRequirement = async (completedDate?: string) => {
        if (tenantUser) {
            if (completedDate) {
                return TenantApi.get(`${Routes.tenant.user}/${tenantUser?.id}/exercise-plans/exercise-baselines/baseline-required?calendarCompletionDate=${completedDate}`)
                    .then((res: {newBaselineRequired: boolean}) => res.newBaselineRequired)
                    .catch(() => true);
            } else {
                return TenantApi.get(`${Routes.tenant.user}/${tenantUser?.id}/exercise-plans/exercise-baselines/baseline-required`)
                    .then((res: {newBaselineRequired: boolean}) => res.newBaselineRequired)
                    .catch(() => true);
            }
        }
        return true;
    };

    const fetchCompletionDateBaseline = async () => {
        setFetchingBaseline(true);
        if (!tenantUser) {
            setFetchingBaseline(false);
            return;
        }
        const { response, error } = await fetchCompletionDateBaselineApi(`/users/${tenantUser.id}/exercise-plans/exercise-baselines?calendarCompletionDate=${completionDate}`);
        setFetchingBaseline(false);
        if (error.hasError) {
            console.error(error.message);
            return;
        }
        if (response.data) {
            setCompletionDateBaseline(response.data);
        }
        if (isCompletionDateOpen) {
            closeCompletionDateModal();
            openPreBaselineModal();
        }
    };

    const updateCompletionDate = (dateCompleted: string) => {
        setCompletionDate(dateCompleted);
        setIsInputData(true);
    };

    const loadHistory = async () => {
        if (date && tenantUser) {
            setLoadingHistory(true);
            TenantApi.get(`/users/${tenantUser?.id}/exercise-plans/daily-summary`, {
                params: {
                    startDate: new Date(date).toISOString(),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    type: utils.ExerciseFilter.EXERCISE
                }
            }).then((res: IExerciseDaySummary) => {
                setDaySummary(res);
                const physical = res.exercises;
                setPhysicalExercises(physical);
                const nextExerciseIndex = physical.findIndex((exercise) => !exercise.complete && exercise.exerciseInfo.type !== ExerciseTypes.ExerciseType.SETS_AND_BREATHS);
                if (nextExerciseIndex) {
                    setCurrentIndex(nextExerciseIndex);
                }
            }).catch((error) => {
                console.error('Error fetching exercise summary', error);
            }).finally(() => {
                setLoadingHistory(false);
            });
        }
    };

    const submitPostExercise = async (metrics: PostExerciseMetrics, hasWarning?: boolean) => {
        setSubmittingBaseline(true);
        const submission: ExerciseTypes.IAssignedExerciseSubmission = {
            ...metrics,
            id: null,
            startTime,
            assignedExerciseId: selectedExercise?.id ? selectedExercise.id : -1,
            exerciseBaselineId: completionDateBaseline?.id ? completionDateBaseline.id : -1,
            calendarCreatedAt: format(new Date(), 'yyyy-MM-dd'),
            calendarCompletionDate: completionDate,
        };

        const res = await createSubmission('/exercise-plans/exercise-submissions', submission);
        setSubmittingBaseline(false);
        if (res.error.hasError) {
            toast.error('Error submitting exercise, please try again and contact support if the problem persists.');
            return;
        }
        closePostBaselineModal();
        toast.success('Post-exercise submitted successfully');
        setSubmittedPostBaseline(true);
        if (!hasWarning && !isInputData) {
            navigate(-1);
        }
    };

    useEffect(() => {
        if (isCompletionDateOpen && isInputData) {
            fetchCompletionDateBaseline();
        }
    }, [isInputData]);

    useEffect(() => {
        loadHistory();
    }, [date, tenantUser]);

    useEffect(() => {
        loadHistory();
        setSubmittedPostBaseline(false);
    }, [submittedPostBaseline]);

    useEffect(() => {
        if (date) {
            setIsCompletable(daySummary?.complete || isWithinInterval(new Date(date), { start: subDays(new Date(), 6), end: new Date() }));
        }
    }, [date, daySummary]);

    useEffect(() => {
        if (shouldRedirectToExercisePage && selectedExercise) {
            navigate(`/modal/exercise/${date}/${selectedExercise.id}`);
        }
    }, [shouldRedirectToExercisePage, selectedExercise]);

    useEffect(() => {
        // redirect if new baseline is created without warnings
        if (doneSavingBaseline && !baselineError && !warningTriggered) {
            setShouldRedirectToExercisePage(true);
        }
    }, [doneSavingBaseline, baselineError]);

    useEffect(() => {
        if (completionDateBaselineSaved && !completionDateBaselineError) {
            fetchCompletionDateBaseline();
            openPostBaselineModal();
        }
    }, [completionDateBaselineSaved, completionDateBaselineError]);

    if (loadingHistory) {
        return <Spinner />;
    }

    if (!date || !daySummary) {
        return null;
    }

    const submitBaseline = async (baseLineSubmission: ExerciseTypes.ICreatedBaseline | null) => {
        if (baseLineSubmission) {
            if (isInputData) {
                createCompletionDateBaseline({ baseline: baseLineSubmission });
            } else {
                createBaseline({ baseline: baseLineSubmission });
            }
        }
    };

    const shouldShowPreExerciseWarning = (metrics: ExerciseTypes.ICreatedBaseline) => {
        let warningCount = 0;
        let bpWarning: null | ExerciseTypes.WarningType = null;
        if (metrics.heartRate < 50) {
            warningCount += 1;
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_LOW);
        } else if (metrics.heartRate > 110) {
            warningCount += 1;
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_HIGH);
        }

        if (metrics.spO2 < 88 || (daySummary && metrics.spO2 < daySummary.spO2PercentTarget)) {
            warningCount += 1;
            setWarningType(ExerciseTypes.WarningType.SPO2);
        }

        if (metrics.bpSystolic && metrics.bpDiastolic && (metrics.bpSystolic < 90)) {
            // don't increment if we've already set a low BP warning for the other BP
            if (bpWarning === null || bpWarning === ExerciseTypes.WarningType.BPSYSTOLIC_HIGH) {
                warningCount += 1;
            }
            bpWarning = ExerciseTypes.WarningType.BPSYSTOLIC_LOW;
            setWarningType(ExerciseTypes.WarningType.BPSYSTOLIC_LOW);
        } else if (metrics.bpSystolic && metrics.bpDiastolic
            && (metrics.bpSystolic > 179 || metrics.bpDiastolic > 100)) {
            // don't increment if we've already set a low BP warning for the other BP
            if (bpWarning === null || bpWarning === ExerciseTypes.WarningType.BPSYSTOLIC_LOW) {
                warningCount += 1;
            }
            bpWarning = ExerciseTypes.WarningType.BPSYSTOLIC_HIGH;
            setWarningType(ExerciseTypes.WarningType.BPSYSTOLIC_HIGH);
        }
        metrics.calendarCompletionDate = completionDate;

        if (warningCount > 0 && !isInputData) {
            setWarningTriggered(true);
            if (warningCount > 1) {
                setWarningType(ExerciseTypes.WarningType.MULTIPLE);
            }
            closePreBaselineModal();
            submitBaseline(metrics);
            openWarningModal();
        } else {
            setWarningTriggered(false);
            closePreBaselineModal();
            submitBaseline(metrics);
        }
    };

    const shouldShowPostExerciseWarningPost = (metrics: PostExerciseMetrics) => {
        let warningCount = 0;
        if (metrics.heartRate && metrics.heartRate < 50) {
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_LOW);
            warningCount += 1;
        } else if (metrics.heartRate && metrics.heartRate > 150) {
            setWarningType(ExerciseTypes.WarningType.HEARTRATE_HIGH);
            warningCount += 1;
        }

        if ((metrics.immediateSpO2Percent && (metrics.immediateSpO2Percent < 85 || metrics.immediateSpO2Percent < daySummary.spO2PercentTarget)) || (metrics.lowestSpO2Percent && (metrics.lowestSpO2Percent < 85 || metrics.lowestSpO2Percent < daySummary.spO2PercentTarget))) {
            setWarningType(ExerciseTypes.WarningType.SPO2);
            warningCount += 1;
        }

        if (warningCount > 0 && !isInputData) {
            if (warningCount > 1) {
                setWarningType(ExerciseTypes.WarningType.MULTIPLE);
            }
            submitPostExercise(metrics, true);
            closePreBaselineModal();
            openWarningModal();
        } else {
            submitPostExercise(metrics);
            setIsInputData(false);
        }
    };

    const exerciseStart = (exercise: ExerciseTypes.IExerciseSummary, index: number) => {
        Mixpanel.track('web_start_exercise', {
            exercise: exercise.exerciseInfo.title,
            date,
            exerciseNumber: index + 1,
        });
        const assignedExercise = physicalExercises.find((ae) => ae.id === exercise.id);
        if (assignedExercise) {
            setSelectedExercise(assignedExercise);
            fetchBaselineRequirement().then((baseline) => {
                if (baseline || daySummary?.complete) {
                    openChecklistModal();
                } else {
                    // if no baseline is required, we can go straight to the exercise
                    setShouldRedirectToExercisePage(true);
                }
            });
        }
    };

    const onExerciseStart = (exercise: ExerciseTypes.IExerciseSummary, index: number) => {
        const assignedExercise = physicalExercises.find((ae) => ae.id === exercise.id);
        if (assignedExercise) {
            setSelectedExercise(assignedExercise);
            if (isToday(parseISO(date)) || daySummary.complete) {
                exerciseStart(assignedExercise, index);
            } else {
                openCompletionCheckModal();
                setSelectedIndex(index);
            }
        }
    };

    const getNumExercisesString = (): string => {
        const numNonRMT = physicalExercises.length;
        return `${numNonRMT} ${numNonRMT > 1 ? 'exercises' : 'exercise'}`;
    };

    return (
        <>
            {isChecklistOpen && (
                <PreExerciseChecklist
                  onClose={() => closeChecklistModal()}
                  onSubmit={() => {
                    closeChecklistModal();
                    if (daySummary.complete) {
                        navigate(`/modal/exercise/${date}/${selectedExercise?.id}`);
                    } else {
                        openPreBaselineModal();
                    }
                    Mixpanel.track('web_pre_exercise_checklist_submitted');
                  }}
                />
            )}
            {isCompletionCheckOpen && (
                <ExerciseCompletionCheckModal
                  onClose={closeCompletionCheckModal}
                  onSubmit={(startExerciseToday: boolean) => {
                    if (startExerciseToday) {
                        setCompletionDate(today);
                        exerciseStart(selectedExercise as ExerciseTypes.IExerciseSummary, selectedIndex);
                    } else {
                        openCompletionDateModal();
                    }
                    closeCompletionCheckModal();
                  }}
                />
            )}
            {isCompletionDateOpen && (
                <ExerciseCompletionDateModal
                  onClose={closeCompletionDateModal}
                  onSubmit={updateCompletionDate}
                  assignedDate={date}
                  submitting={fetchingBaseline}
                />
            )}
            {isPreBaselineOpen && (
                <PreExerciseBaseline
                  exercisePlanId={daySummary.planId}
                  onClose={() => {
                    closePreBaselineModal();
                    setIsInputData(false);
                }}
                  checkSubmission={(submission: ExerciseTypes.ICreatedBaseline) => shouldShowPreExerciseWarning(submission)}
                  latestBaseline={completionDateBaseline}
                />
            )}
            {isPostBaselineOpen && selectedExercise && (
                <PostExerciseBaseline
                  exercise={selectedExercise}
                  onClose={() => {
                    closePostBaselineModal();
                    setIsInputData(false);
                }}
                  checkSubmission={(metrics: PostExerciseMetrics) => shouldShowPostExerciseWarningPost(metrics)}
                  showOxygen={completionDateBaseline ? !!completionDateBaseline.flowRate : true}
                  submitting={submittingBaseline}
                />
            )}
            {isWarningOpen && (
            <BaselineWarning
              onClose={() => closeWarningModal()}
              sp02Target={daySummary?.spO2PercentTarget as number}
              openBaselineModal={() => {
                closeWarningModal();
                openPreBaselineModal();
            }}
              warningType={warningType}
            />
            )}
            <Page>
                <S.Section>
                    <S.BackButton
                      buttonType="tertiary"
                      to="/exercises"
                    >
                        <FontAwesomeIcon icon={faChevronLeft as IconProp} /> Back to all upcoming exercises
                    </S.BackButton>
                </S.Section>
                <S.Section>
                    {isToday(new Date(date)) ? (<S.Heading>Today&apos;s Exercises</S.Heading>) : (<S.Heading>{utils.getMonthAndDate(new Date(date)) } Exercises</S.Heading>)}
                </S.Section>
                <S.Section>
                    { isCompletable
                        ? <S.Subheading>{utils.getMonthAndDate(new Date(date))}, {new Date(date).getFullYear()} has {getNumExercisesString()} </S.Subheading> : <S.Subheading>These exercises will unlock on {utils.getMonthAndDate(new Date(date))}</S.Subheading>}
                </S.Section>
                <S.Section>
                    {allAreCompleted && isCompletable && <S.AllCompletedBanner>You&apos;re done for today!</S.AllCompletedBanner>}
                    {!!physicalExercises.length && (
                        <S.CardContainer>
                                {physicalExercises.map((exercise: ExerciseTypes.IExerciseSummary, index: number) => (
                                    <ExerciseCard
                                      key={exercise.id}
                                      exercise={exercise}
                                      onStart={() => onExerciseStart(exercise, index)}
                                      isCurrentExercise={isCurrentExercise(index)}
                                      canComplete={isCompletable}
                                      exerciseIndex={`${index + 1} of ${physicalExercises.length}`}
                                      date={date}
                                      isPlanCompleted={daySummary.complete}
                                    />
                            ))}
                        </S.CardContainer>
                    )}
                </S.Section>
            </Page>
        </>
    );
};

export default ExercisesForDay;
