var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    padding: ", ";\n    min-width: ", ";\n    max-width: ", ";\n    min-height: ", ";\n    max-height: ", ";\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    flex-direction: column;\n    background-color: white;\n    z-index: 100;\n    top: ", ";\n    right: 50%;\n    transform: translate(50%, -50%);\n    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);\n    border-radius: ", ";\n    overflow: ", ";\n"], ["\n    position: fixed;\n    padding: ", ";\n    min-width: ", ";\n    max-width: ", ";\n    min-height: ", ";\n    max-height: ", ";\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    flex-direction: column;\n    background-color: white;\n    z-index: 100;\n    top: ", ";\n    right: 50%;\n    transform: translate(50%, -50%);\n    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);\n    border-radius: ", ";\n    overflow: ", ";\n"])), function (props) { return props.padded ? '20px' : '0px;'; }, function (props) { return props.minWidth ? props.minWidth : '50%'; }, function (props) { return props.maxWidth ? props.maxWidth : '700px'; }, function (props) { return props.minHeight ? props.minHeight : '30%'; }, function (props) { return props.maxHeight ? props.maxHeight : '700px'; }, function (props) { return props.top ? props.top : '50%'; }, function (props) { return props.borderRadius ? props.borderRadius : '5px'; }, function (props) { return props.overflow ? props.overflow : 'auto'; });
export var ModalDivider = styled.hr(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 100%;\n"], ["\n    width: 100%;\n"])));
export var ModalHeader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    h3 {\n        margin: 8px 0;\n    }\n    button {\n        padding: 0;\n        margin-right: 0;\n        align-self: flex-end;\n    }\n"], ["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    h3 {\n        margin: 8px 0;\n    }\n    button {\n        padding: 0;\n        margin-right: 0;\n        align-self: flex-end;\n    }\n"])));
export var ModalContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    flex: 2;\n"], ["\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    flex: 2;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
