var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { LinkButton } from '../../../Button';
export var TicketContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n"], ["\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n"])));
export var TicketImg = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: 40px;\n    padding-top: 60px;\n    margin-bottom: 20px;\n"], ["\n    width: 40px;\n    padding-top: 60px;\n    margin-bottom: 20px;\n"])));
export var TicketText = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    margin-top: 0;\n    margin-bottom: 20px;\n    text-align: center;\n"], ["\n    margin-top: 0;\n    margin-bottom: 20px;\n    text-align: center;\n"])));
export var BoldTicketText = styled(TicketText)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-weight: bold;\n"], ["\n    font-weight: bold;\n"])));
export var HomeButton = styled(LinkButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: ", ";\n    background: ", ";\n    text-align: center;\n    margin-top: 20px;\n"], ["\n    color: ", ";\n    background: ", ";\n    text-align: center;\n    margin-top: 20px;\n"])), function (props) { return props.theme.buttons.white; }, function (props) { return props.theme.shades.blue; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
