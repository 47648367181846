import styled from 'styled-components';

const Select = styled.select<{ error?: boolean, size?: 'small' | 'medium' | 'large' }>`
  border: 1px solid ${props => (props.error ? props.theme.text.error : props.theme.shades.lightGrey)};
  border-radius: 3px;
  padding: 10px;
  margin: 8px 8px 8px 10px;
  width: ${props => {
        if (props.size === 'small') {
            return '100px';
        } else if (props.size === 'medium') {
            return '200px';
        } else if (props.size === 'large') {
            return '300px';
        }
        return '100%';
    }};
  background-color: ${props => props.theme.shades.offWhite};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default Select;
