var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './index.styles';
import { theme } from '../../theme';
var OptionSelector = function (_a) {
    var options = _a.options, onSelect = _a.onSelect, width = _a.width, value = _a.value, optionWidth = _a.optionWidth;
    var handleSelect = function (optionValue) {
        onSelect(optionValue);
    };
    return (_jsx(S.SelectorContainer, __assign({ style: {
            width: width || '100%',
        } }, { children: options.map(function (option) {
            var _a;
            return (_jsx(S.Option, __assign({ onClick: function () { return handleSelect(option.value); }, style: {
                    backgroundColor: value === option.value ? theme.buttons.blue : theme.shades.lighterGrey,
                    color: value === option.value ? theme.text.white : theme.text.black,
                    flex: optionWidth !== undefined ? '0 1 auto' : '1',
                    width: optionWidth !== undefined ? 'auto' : optionWidth,
                } }, { children: option.display }), ((_a = option.value) === null || _a === void 0 ? void 0 : _a.toString()) || 'null'));
        }) })));
};
export default OptionSelector;
