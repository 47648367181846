import { Buttons, Card, useModal } from '@apps/common-ui';
import React from 'react';
import * as S from './index.styles';
import BreatherCleaningTips from '../BreatherCleaningTips';

const BreatherCard = () => {
    const { closeModal, isOpen, openModal } = useModal();

    return (
        <>
            {isOpen && (
                <BreatherCleaningTips
                  showModal={isOpen}
                  dismissModal={closeModal}
                />
            )}
            <S.BreatherCard>
                <S.CardHeader>
                    <S.CardImage src="/BreatherImage.png" alt="Breather" />
                    <S.Heading>Breather Cleaning Tips</S.Heading>
                </S.CardHeader>
                <p>To ensure a sanitary Breather, clean with warm soapy water daily, santize once a week, for more details
                    <Buttons.Button
                      buttonType="tertiary"
                      onClick={() => openModal()}
                    >
                        click here.
                    </Buttons.Button>
                </p>
            </S.BreatherCard>
        </>
    );
};
export default BreatherCard;
