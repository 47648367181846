var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import * as S from './index.styles';
import { useOnClickOutside } from '@apps/common-utilities';
import { Button } from '../Button';
import TranslucentBackground from '../TranslucentBackground';
/**
 * Hook used alongside the component to control when to show/hide the modal
 */
export var useModal = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var openModal = function () {
        setIsOpen(true);
    };
    var closeModal = function () {
        setIsOpen(false);
    };
    return {
        isOpen: isOpen,
        openModal: openModal,
        closeModal: closeModal
    };
};
var Modal = function (_a) {
    var showModal = _a.showModal, dismissModal = _a.dismissModal, children = _a.children, title = _a.title, style = _a.style, _b = _a.showTopBar, showTopBar = _b === void 0 ? true : _b, minWidth = _a.minWidth, maxWidth = _a.maxWidth, minHeight = _a.minHeight, maxHeight = _a.maxHeight, _c = _a.closeOnOutsideClick, closeOnOutsideClick = _c === void 0 ? true : _c, _d = _a.showTranslucentBackground, showTranslucentBackground = _d === void 0 ? true : _d, _e = _a.padded, padded = _e === void 0 ? true : _e, _f = _a.top, top = _f === void 0 ? '50%' : _f, _g = _a.borderRadius, borderRadius = _g === void 0 ? '5px' : _g, _h = _a.overflow, overflow = _h === void 0 ? 'auto' : _h, _j = _a.closeLabel, closeLabel = _j === void 0 ? 'Close' : _j;
    var ref = useRef(null);
    var dismissOnClickOutside = function () {
        if (closeOnOutsideClick) {
            dismissModal();
        }
    };
    useOnClickOutside(ref, dismissOnClickOutside);
    useEffect(function () {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
        return function () {
            document.body.style.overflow = 'unset';
        };
    }, [showModal]);
    if (!showModal)
        return null;
    return (_jsx(TranslucentBackground, __assign({ showBackground: showTranslucentBackground }, { children: _jsxs(S.ModalWrapper, __assign({ ref: ref, style: style, minWidth: minWidth || '', maxWidth: maxWidth || '', minHeight: minHeight || '', maxHeight: maxHeight || '', padded: padded, top: top, borderRadius: borderRadius, overflow: overflow }, { children: [showTopBar && (_jsxs(_Fragment, { children: [_jsxs(S.ModalHeader, { children: [_jsx("h3", { children: title }), _jsxs(Button, __assign({ size: "small", buttonType: "tertiary", onClick: dismissModal }, { children: ["x ", closeLabel] }))] }), _jsx(S.ModalDivider, {})] })), _jsx(S.ModalContent, { children: children })] })) })));
};
export default Modal;
