var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Buttons, Card } from '../..';
export var SupportText = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n  margin-top 0;\n  margin-bottom: 5px;\n  margin-left: 20px;\n  margin-right: 20px;\n  @media (max-width: ", ") {\n    text-align: start;\n  }\n"], ["\n  text-align: center;\n  margin-top 0;\n  margin-bottom: 5px;\n  margin-left: 20px;\n  margin-right: 20px;\n  @media (max-width: ", ") {\n    text-align: start;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var SupportImg = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 60px;\n  padding-top: 40px;\n  @media (max-width: ", ") {\n    padding-top: 0;\n  }\n"], ["\n  width: 60px;\n  padding-top: 40px;\n  @media (max-width: ", ") {\n    padding-top: 0;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var DesktopText = styled(SupportText)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline;\n  margin-bottom: 20px;\n  font-weight: bold;\n  @media (max-width: ", ") {\n    display: none;\n  }\n"], ["\n  display: inline;\n  margin-bottom: 20px;\n  font-weight: bold;\n  @media (max-width: ", ") {\n    display: none;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var MobileButton = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  display: none;\n  color: ", ";\n  background: ", ";\n  text-align: center;\n  margin-left: 20px;\n  margin-right: 20px;\n  margin-bottom: 20px;\n  width: 87%;\n  @media (max-width: ", ") {\n    display: block;\n  }\n\n  & svg:first-of-type {\n    margin-right: 10px;\n  }\n"], ["\n  ", "\n  display: none;\n  color: ", ";\n  background: ", ";\n  text-align: center;\n  margin-left: 20px;\n  margin-right: 20px;\n  margin-bottom: 20px;\n  width: 87%;\n  @media (max-width: ", ") {\n    display: block;\n  }\n\n  & svg:first-of-type {\n    margin-right: 10px;\n  }\n"])), Buttons.ButtonStyles, function (props) { return props.theme.buttons.white; }, function (props) { return props.theme.shades.blue; }, function (props) { return props.theme.screen.medium; });
export var MobileText = styled(SupportText)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: none;\n  margin-bottom: 20px;\n  @media (max-width: ", ") {\n    display: inline;\n  }\n"], ["\n  display: none;\n  margin-bottom: 20px;\n  @media (max-width: ", ") {\n    display: inline;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var ButtonIcon = styled(FontAwesomeIcon)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 14px;\n  height: 14px;\n"], ["\n  width: 14px;\n  height: 14px;\n"])));
export var HeadingContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  @media (max-width: ", ") {\n    flex-direction: row;\n    justify-content: space-between;\n    margin: 20px;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  @media (max-width: ", ") {\n    flex-direction: row;\n    justify-content: space-between;\n    margin: 20px;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var SupportCard = styled(Card)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 0px;\n  width: 300px;\n  height: 300px;\n  margin: 0 0 40px 0;\n  @media (max-width: ", ") {\n    margin-bottom: 20px;\n    align-items: flex-start;\n    width: 80vw;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 0px;\n  width: 300px;\n  height: 300px;\n  margin: 0 0 40px 0;\n  @media (max-width: ", ") {\n    margin-bottom: 20px;\n    align-items: flex-start;\n    width: 80vw;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var DesktopTextInfo = styled(SupportText)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: inline;\n  margin-bottom: 54px;\n  @media (max-width: ", ") {\n    display: none;\n  }\n"], ["\n  display: inline;\n  margin-bottom: 54px;\n  @media (max-width: ", ") {\n    display: none;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var SubHeader = styled.h2(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 20px;\n  font-weight: normal;\n  @media (max-width: ", ") {\n    margin: 0;\n    margin-left: 20px;\n    padding-bottom: 0;\n    padding-top: 0;\n  }\n"], ["\n  margin-top: 20px;\n  font-weight: normal;\n  @media (max-width: ", ") {\n    margin: 0;\n    margin-left: 20px;\n    padding-bottom: 0;\n    padding-top: 0;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var DesktopCallInfo = styled(SupportText)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: inline;\n  margin-bottom: 30px;\n  @media (max-width: ", ") {\n    display: none;\n  }\n"], ["\n  display: inline;\n  margin-bottom: 30px;\n  @media (max-width: ", ") {\n    display: none;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var EmailButton = styled(Buttons.Button)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 90%;\n  color: ", ";\n  background: ", ";\n  text-align: center;\n  margin-top: 14px;\n  margin-bottom: 35px;\n  @media (max-width: ", ") {\n    margin-left: 20px;\n    margin-right: 20px;\n    width: 87%;\n    margin-bottom: 20px;\n  }\n"], ["\n  width: 90%;\n  color: ", ";\n  background: ", ";\n  text-align: center;\n  margin-top: 14px;\n  margin-bottom: 35px;\n  @media (max-width: ", ") {\n    margin-left: 20px;\n    margin-right: 20px;\n    width: 87%;\n    margin-bottom: 20px;\n  }\n"])), function (props) { return props.theme.buttons.white; }, function (props) { return props.theme.shades.blue; }, function (props) { return props.theme.screen.medium; });
export var SupportContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 1100px;\n  margin: 0 20px;\n\n  @media (max-width: ", ") {\n    width: 100vw;\n    padding: 0 20px;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 1100px;\n  margin: 0 20px;\n\n  @media (max-width: ", ") {\n    width: 100vw;\n    padding: 0 20px;\n  }\n"])), function (props) { return props.theme.screen.medium; });
export var TicketSubmissionContainer = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])));
export var BackButton = styled(Buttons.Button)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  align-self: flex-start;\n\n  & svg:last-of-type {\n    margin-left: 0;\n  }\n"], ["\n  margin: 0;\n  padding: 0;\n  align-self: flex-start;\n\n  & svg:last-of-type {\n    margin-left: 0;\n  }\n"])));
export var Header = styled(SubHeader)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  @media (max-width: ", ") {\n    margin-left: 0;\n    padding-bottom: 20px;\n    padding-top: 20px;\n  }\n"], ["\n  @media (max-width: ", ") {\n    margin-left: 0;\n    padding-bottom: 20px;\n    padding-top: 20px;\n  }\n"])), function (props) { return props.theme.screen.medium; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
