import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useActionLoader } from '../../../hooks/useActionLoader';
import { loginUser, setCurrentTenantUser } from '../../../state/reducers/session/actions';
import * as S from '../index.styles';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { SimpleSignUpUser } from '../../../types/models';
import Mixpanel from '../../../services/Mixpanel';
import { InputSize } from '@apps/common-ui/src/Input';
import { Spinner, theme } from '@apps/common-ui';
import { Routes } from '../../../api/Routes';
import { TenantApi } from '../../../api/UsersApi';

const SimpleSignUpForm = () => {
    const { t } = useTranslation();
    const { callAction: setUser } = useActionLoader(setCurrentTenantUser);
    const { callAction: login, error: loginError } = useActionLoader(loginUser);
    const [loading, setLoading] = useState<boolean>(false);
    const { loggedIn } = useSelector((state: RootState) => state.session);
    const [error, setError] = useState<string | null>(null);
    const [accountCreationError, setAccountCreationError] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const navigate = useNavigate();

    const width = window.innerWidth;
    const breakpoint = Number(theme.screen.small.split('px')[0]);

    useEffect(() => {
        if (loggedIn) {
            navigate('/');
        }
    }, [loggedIn]);

    // for mixpanel
    useEffect(() => {
        Mixpanel.track('web_sign_up_page');
    }, []);

    const schema = yup.object({
        firstName: yup.string().required(t('login.error.firstNameRequired')),
        lastName: yup.string().required(t('login.error.lastNameRequired')),
        email: yup.string().trim().email(t('login.error.emailIsInvalid')).required(t('login.error.emailRequired')),
        password: yup.string().required(t('login.error.passwordRequired')).min(10, t('login.error.passwordTooShort')),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match').required('Please confirm your password')
    }).required();

    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const formSub = watch((values) => {
            setSubmittable(Object.values(values).every((val) => !!val === true));
        });
        return () => formSub.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (loginError) {
            setLoading(false);
        }
    }, [loginError]);

    const signup = async (data: any) => {
        setError('');

        const { firstName, lastName, email, password, confirmPassword } = data;
        const loweredEmail = email.toLowerCase();
        const firstNameTrim = data.firstName.trim();
        const lastNameTrim = data.lastName.trim();

        setLoading(true);
        const authUser = await Auth.signUp({
            username: loweredEmail,
            password,
            attributes: {
                email: loweredEmail
            }
        }).catch(err => {
            setLoading(false);
            switch (err.code) {
                case 'UsernameExistsException':
                    setError(t('login.error.emailAlreadyExists'));
                    break;
                default:
                    setError(t('login.error.unknownError'));
                    break;
            }
        });
        if (authUser) {
            const user: SimpleSignUpUser = {
                id: authUser.userSub,
                email: loweredEmail,
                firstName: firstNameTrim,
                lastName: lastNameTrim,
                phoneNumber: null,
                dateOfBirth: null,
                sexAssignedAtBirth: null,
                medicalCondition: null,
                height: null,
                weight: null,
                groupCode: null,
            };
            await Auth.signIn(loweredEmail, password).catch(err => {
                setAccountCreationError('Something went wrong please refresh the page and contact support if the error persists.');
            });
            setUser(user);
            TenantApi.post(Routes.tenant.user, user).then((res) => {
                if (res) {
                    login({ user });
                    navigate('/onboarding');
                } else {
                    setAccountCreationError('Something went wrong please refresh the page and contact support if the error persists.');
                }
            }).catch((err) => {
                setAccountCreationError('Something went wrong please refresh the page and contact support if the error persists.');
            });
        }
    };

    return (
        <S.LoginContainer>
            <S.TopBar mobileRightSidePadding="22px">
                {width >= breakpoint && <S.TopBarLogo src="/BlueLogoNoBackground.svg" />}
                {width < breakpoint && <S.TopBarLogo src="/MobileLogoNoTextOrBackground.svg" />}
                <S.TopBarButtonsGroup>
                    <S.TopBarButton onClick={() => navigate('/login')}>Login</S.TopBarButton>
                    <S.TopBarButton
                      onClick={() => navigate('/support', { state: { previousPage: '/sign-up' } })}
                    >Get Help
                    </S.TopBarButton>
                </S.TopBarButtonsGroup>
            </S.TopBar>
            <S.SignUpFormContainer>
                <S.SignUpForm onSubmit={handleSubmit(signup)}>
                    <S.FormTitle noTopMargin>Sign Up</S.FormTitle>
                    <S.FormRow columnOnMobile>
                        <S.SignUpAuthInputContainer>
                            <S.LoginLabel htmlFor="firstName">{`${t('login.firstName')}`}</S.LoginLabel>
                            <S.SignUpInput
                              {...register('firstName')}
                              id="firstName"
                            />
                            {errors.firstName && <S.Message type="error">{errors.firstName.message}</S.Message>}
                        </S.SignUpAuthInputContainer>
                        <S.SignUpAuthInputContainer>
                            <S.LoginLabel htmlFor="lastName">{`${t('login.lastName')}`}</S.LoginLabel>
                            <S.SignUpInput
                              {...register('lastName')}
                              id="lastName"
                            />
                            {errors.lastName && <S.Message type="error">{errors.lastName.message}</S.Message>}

                        </S.SignUpAuthInputContainer>
                    </S.FormRow>
                    <S.SignUpAuthInputContainer isFullWidth>
                        <S.LoginLabel htmlFor="email">What is your email?</S.LoginLabel>
                        <S.SignUpInput
                          {...register('email')}
                          id="email"
                          isFullWidth
                        />
                        {errors.email && <S.Message type="error">{errors.email.message}</S.Message>}
                    </S.SignUpAuthInputContainer>
                    <S.FormRow columnOnMobile columnOnTablet>
                        <S.SignUpAuthInputContainer isFullWidthOnMobile>
                            <S.LabelWithTipContainer>
                                <S.LoginLabel htmlFor="password">{`${t('login.password')}`}</S.LoginLabel>
                                <S.LabelTip>Include at least 10 letters or numbers.</S.LabelTip>
                            </S.LabelWithTipContainer>
                            <S.SignUpPasswordWithButtonContainer>
                                <S.SignUpInput
                                  {...register('password')}
                                  type={showPassword ? 'text' : 'password'}
                                  id="password"
                                  isFullWidth
                                />
                                <S.SignUpPasswordButton type="button" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>{showPassword ? 'HIDE' : 'SHOW'}</S.SignUpPasswordButton>
                            </S.SignUpPasswordWithButtonContainer>
                            {errors.password && <S.Message type="error">{errors.password.message}</S.Message>}
                        </S.SignUpAuthInputContainer>
                        <S.SignUpAuthInputContainer isFullWidthOnMobile>
                            <S.LabelWithTipContainer>
                                <S.LoginLabel htmlFor="confirmPassword" nowWrap>Confirm Password</S.LoginLabel>
                            </S.LabelWithTipContainer>
                            <S.SignUpPasswordWithButtonContainer>
                                <S.SignUpInput
                                  {...register('confirmPassword')}
                                  type={showConfirmPassword ? 'text' : 'password'}
                                  id="confirmPassword"
                                  isFullWidth
                                />
                                <S.SignUpPasswordButton type="button" onClick={() => setShowConfirmPassword(!showConfirmPassword)} tabIndex={-1}>{showConfirmPassword ? 'HIDE' : 'SHOW'}</S.SignUpPasswordButton>
                            </S.SignUpPasswordWithButtonContainer>
                            {errors.confirmPassword && <S.Message type="error">{errors.confirmPassword.message}</S.Message>}
                        </S.SignUpAuthInputContainer>
                    </S.FormRow>
                    {error && <S.Message type="error">{error}</S.Message>}
                    <S.SubmitButton type="submit" submittable={submittable} fullContainerWidth disabled={loading}>{loading ? <Spinner small /> : 'Create Account'}</S.SubmitButton>
                    {accountCreationError && <S.Message type="error">{accountCreationError}</S.Message>}
                    <S.SignUpParagraph>
                        {'By clicking "Create Account", you agree to create an account, BreatheSuite\'s '}
                        <S.SignUpLink href="https://www.breathesuite.com/termsofuse" target="_blank">Terms &amp; Conditions</S.SignUpLink>{' and '}
                        <S.SignUpLink href="https://www.breathesuite.com/privacypolicy" target="_blank">Privacy Policy.</S.SignUpLink>
                    </S.SignUpParagraph>
                    <S.AlreadyHaveAccountContainer>
                        <S.AlreadyHaveAccountParagraph>Already have an account?</S.AlreadyHaveAccountParagraph>
                        <S.AlreadyHaveAccountButton type="button" onClick={() => navigate('/login')}>Login</S.AlreadyHaveAccountButton>
                    </S.AlreadyHaveAccountContainer>
                    <S.MobileSignUpImage src="/MobileLogoNoTextOrBackground.svg" />
                </S.SignUpForm>
            </S.SignUpFormContainer>
        </S.LoginContainer>
    );
};

export default SimpleSignUpForm;
