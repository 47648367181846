var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from '../index.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import TicketSubmission from './TicketCompletion';
import TicketCreation from './TicketCreation';
var SupportTicket = function (_a) {
    var onBackClicked = _a.onBackClicked, submitTicket = _a.submitTicket, completed = _a.completed, tenantUser = _a.tenantUser, completedMessage = _a.completedMessage, completedImage = _a.completedImage, message = _a.message, issueRequired = _a.issueRequired, issueList = _a.issueList, mixpanelTrack = _a.mixpanelTrack;
    return (_jsxs(S.SupportContainer, { children: [_jsxs(S.BackButton, __assign({ buttonType: "tertiary", onClick: function () {
                    onBackClicked();
                } }, { children: [_jsx(FontAwesomeIcon, { icon: faChevronLeft }), "Back to support options"] })), completed ? (_jsx(S.TicketSubmissionContainer, { children: _jsx(TicketSubmission, { message: completedMessage, completedImage: completedImage }) })) : (_jsxs(_Fragment, { children: [_jsx(S.Header, { children: "Send us a Support Message" }), _jsx(TicketCreation, { message: message, submitTicket: submitTicket, done: completed, tenantUser: tenantUser, issueRequired: issueRequired, issueList: issueList, mixpanelTrack: mixpanelTrack })] }))] }));
};
export default SupportTicket;
