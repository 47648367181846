import { Card } from '@apps/common-ui';
import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Paragraph = styled.p`
  margin: 5px;
`;

export const List = styled.ol`
  margin-top: 0;
`;

export const ModalContent = styled.div`
  overflow-y: auto;
`;
